@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.admin {
  background-image: url("./admin/assets/hassaan-qaiser-bKfkhVRAJTQ-unsplash.jpg");
  background-size: cover;
  
background-repeat: no-repeat;
background-attachment: fixed;
 

}
.panel{
  background: rgba( 255, 255, 255, 0.62 );

backdrop-filter: blur( 49px );
-webkit-backdrop-filter: blur( 9px );


}
.body{
  background: rgba( 255, 255, 255, 0.33 );

backdrop-filter: blur( 25px );
-webkit-backdrop-filter: blur( 2.5px );
height: 100%;


}
.nav{
  background: rgba( 255, 255, 255, 0.2 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 2.5px );
-webkit-backdrop-filter: blur( 2.5px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
  
}

.cont{
  background: rgba( 255, 255, 255, 0.2 );

  backdrop-filter: blur( 25px );
  -webkit-backdrop-filter: blur( 2.5px );
  height: 100%;
}

.cont2{
  background: rgba( 255, 255, 255, 0.2 );

  backdrop-filter: blur( 30px );
  -webkit-backdrop-filter: blur( 2.5px );
  height: 100%;
}